export const DICOM_TABLE_CONF = [
	{
		label: 'id',
		header: 'ID',
		value: 'id',
	},
	{
		label: 'title',
		header: 'AETITLE',
		value: 'title',
	},
	{
		label: 'hostname',
		header: 'HOSTNAME',
		value: 'hostname',
	},
	{
		label: 'port',
		header: 'PORT',
		value: 'port',
	},
	{
		label: 'retrieveMethod',
		header: 'RETRIEVE_METHOD',
		value: 'retrieveMethod',
	},
	{
		label: 'queryAttribute',
		header: 'QUERY_ATTRIBUTE',
		value: 'queryAttribute',
	},
	{
		label: 'apiEndpoint',
		header: 'API_ENDPOINT',
		value: 'apiEndpoint',
	},
	{
		label: 'activated',
		header: 'ACTIVATED',
		value: 'activated',
	},
	{
		label: 'principal',
		header: 'PRINCIPAL',
		value: 'principal',
	},
];

export const HL7_TABLE_CONF = [
	{
		label: 'id',
		header: 'ID',
		value: 'id',
	},
	{
		label: 'label',
		header: 'LABEL',
		value: 'label',
	},
	{
		label: 'hostname',
		header: 'HOSTNAME',
		value: 'hostname',
	},
	{
		label: 'port',
		header: 'PORT',
		value: 'port',
	},
	{
		label: 'supportedMessages',
		header: 'SUPPORTED_MESSAGES',
		value: 'supportedMessages',
	},
	{
		label: 'serviceType',
		header: 'SERVICE_TYPE',
		value: 'serviceType',
	},
	{
		label: 'activated',
		header: 'ACTIVATED',
		value: 'activated',
	},
];

export const CUSTOM_TABLE_CONF = [
	{
		label: 'id',
		header: 'ID',
		value: 'id',
	},
	{
		label: 'label',
		header: 'LABEL',
		value: 'label',
	},
	{
		label: 'hostname',
		header: 'HOSTNAME',
		value: 'hostname',
	},
	{
		label: 'port',
		header: 'PORT',
		value: 'port',
	},
	{
		label: 'queryRangeInMinutes',
		header: 'QUERY_RANGE_IN_MINUTES',
		value: 'queryRangeInMinutes',
	},
	{
		label: 'refreshRateInSeconds',
		header: 'REFRESH_RATE_IN_SECONDS',
		value: 'refreshRateInSeconds',
	},
	{
		label: 'activated',
		header: 'ACTIVATED',
		value: 'activated',
	},
];

export const HL7_SERVICE_TYPES = [
	'BILLING',
	'INVENTORY',
	'PACS',
	'INFORMATION_SYSTEM',
];

export const HL7_MESSAGES: string[] = [
	'ADT_A01', // admit/visit notification
	'ADT_A08', // update patient information
	'ADT_A40', // merge patient
	'OMG_O19', // General Clinical Order
	'OMI_O23', // Imaging Order
	'ORM_O01', // general order message
	'ORU_R01', // Unsolicited transmission of an observation message
	'MDM_T02', // original document notification and content
	'DFT_P03', // Post details financial transaction
	'EHC_E15', // payment remittance advice
	'OMS_O05', // stock requisition order
	'SIU_S12', // Notification of new Appointment Booking
];
