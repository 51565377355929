<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
	<mat-icon fontIcon="mdi-sort" fontSet="mdi"></mat-icon>
	<h3>{{ 'PRIORITIES' | translate }}</h3>
</mat-toolbar>

<div
	class="relative fx-layout-column-nowrap fx-content-center fx-items-center ft-setting-card-width"
	style="padding-top: 24px">
	<mat-accordion>
		@for (priority of priorities; track priority) {
			<mat-expansion-panel (opened)="selectPriority(priority)">
				<mat-expansion-panel-header>
					<mat-panel-title [style.max-width.px]="160">
						<mat-chip-listbox>
							<mat-chip-option
								style="font-weight: bold"
								selectable="false"
								[style.background-color]="priority.color"
								>{{ priority.value }}</mat-chip-option
							>
						</mat-chip-listbox>
					</mat-panel-title>
					<mat-panel-description>
						{{ priority.description }}
					</mat-panel-description>
				</mat-expansion-panel-header>
				<form [formGroup]="priorityForm" class="fx-layout-column">
					<mat-form-field class="fx-fill-width">
						<mat-label>{{ 'NAME' | translate }}</mat-label>
						<input
							matInput
							placeholder="{{ 'NAME' | translate }}"
							formControlName="value" />
					</mat-form-field>

					<mat-form-field class="fx-fill-width">
						<mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
						<textarea
							matInput
							placeholder="{{ 'DESCRIPTION' | translate }}"
							formControlName="description"></textarea>
					</mat-form-field>

					<div class="ft-color">
						<div>{{ 'COLOR' | translate }}:</div>
						<div
							class="color-area"
							[(colorPicker)]="currentPriorityColor"
							[style.background]="currentPriorityColor"></div>
					</div>
				</form>
				<div class="fx-layout-row fx-content-end fx-gap-4">
					@if (![260, 261, 262, 263, 264].includes(priority.id)) {
						<button
							mat-button
							color="warn"
							(click)="deletePriority(priority)">
							{{ 'DELETE' | translate }}
						</button>
					}
					<button
						mat-raised-button
						color="primary"
						(click)="savePriority(priorityForm.value)"
						[disabled]="priorityForm.invalid">
						{{ 'SAVE' | translate }}
					</button>
				</div>
			</mat-expansion-panel>
		}
	</mat-accordion>

	<button
		mat-fab
		[matTooltip]="'ADD_NEW' | translate"
		(click)="addPriority()"
		style="position: fixed; bottom: 46px; right: 56px">
		<mat-icon
			class="add-icon-gradient"
			fontIcon="mdi-plus"
			fontSet="mdi"></mat-icon>
	</button>
</div>
