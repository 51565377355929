<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
	<mat-icon fontIcon="mdi-label-multiple" fontSet="mdi"></mat-icon>
	<h3>{{ 'LABELS' | translate }}</h3>

	<span class="fx-grow-1"></span>

	<button mat-button (click)="editLabel(null)">
		<mat-icon
			class="add-icon-gradient"
			fontIcon="mdi-plus"
			fontSet="mdi"></mat-icon>
		{{ 'ADD_NEW' | translate }}
	</button>
</mat-toolbar>

<div
	class="fx-search-area fx-layout-row fx-content-end fx-items-center fx-gap-4 fx-padding-8">
	<mat-form-field subscriptSizing="dynamic">
		<mat-icon fontIcon="mdi-magnify" fontSet="mdi" matIconPrefix></mat-icon>
		<input matInput #filter placeholder="{{ 'SEARCHING' | translate }}" />
	</mat-form-field>
	<span class="fx-grow-1"></span>

	<div class="fx-layout-column-nowrap">
		<span class="label-option">{{ 'LABEL_MODULE' | translate }}</span>
		<mat-button-toggle-group
			aria-label="labelModule"
			multiple
			name="labelModule"
			[formControl]="labelModuleForm">
			@for (lbl of labelModules; track lbl) {
				<mat-button-toggle [value]="lbl">
					{{ lbl | translate }}
				</mat-button-toggle>
			}
		</mat-button-toggle-group>
	</div>
</div>

@if (isLoadingResults) {
	<mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
}
<div class="relative fx-layout-column-nowrap ft-setting-card-width">
	<mat-table
		[dataSource]="dataSource"
		class="fx-grow-1 fx-overflow-auto"
		style="height: calc(100vh - 226px) !important; padding: 0 16px"
		matSort
		matSortActive="value"
		matSortDisableClear
		matSortDirection="desc">
		<!--Table-->
		@for (col of displayedColumns; track col) {
			<ng-container [matColumnDef]="col.value">
				<mat-header-cell *matHeaderCellDef mat-sort-header>
					{{ col.header | translate }}
				</mat-header-cell>

				@if (col.header === 'COLOR') {
					<mat-cell *matCellDef="let row">
						<mat-icon
							[style.color]="row[col.value]"
							fontSet="mdi"
							fontIcon="mdi-circle"></mat-icon>
					</mat-cell>
				}
				@if (col.header === 'LABEL_MODULE') {
					<mat-cell *matCellDef="let row">
						<div
							[ngStyle]="{
								background:
									'linear-gradient(45deg, ' +
									getLabelModuleColor(row[col.value]) +
									', white)'
							}"
							class="label-module-badge">
							{{ row[col.label] | translate }}
						</div>
					</mat-cell>
				}
				@if (!['COLOR', 'LABEL_MODULE'].includes(col.header)) {
					<mat-cell *matCellDef="let row">
						{{ row[col.value] }}
					</mat-cell>
				}
			</ng-container>
		}

		<!--actions-->
		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let row" style="text-align: right">
				<button
					mat-icon-button
					[matTooltip]="'EDIT' | translate"
					(click)="editLabel(row)">
					<mat-icon
						class="edit-icon-gradient"
						fontIcon="mdi-pencil"
						fontSet="mdi"></mat-icon>
				</button>
				<button
					mat-icon-button
					[matTooltip]="'DELETE' | translate"
					(click)="deleteLabel(row)">
					<mat-icon
						class="delete-icon-gradient"
						fontIcon="mdi-delete"
						fontSet="mdi"></mat-icon>
				</button>
			</mat-cell>
		</ng-container>

		<!--header-->
		<mat-header-row *matHeaderRowDef="cols; sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: cols"></mat-row>
	</mat-table>

	<div
		class="fx-table-empty"
		[style.display]="resultsLength === 0 ? '' : 'none'">
		{{ 'NOITEMSFOUND' | translate }}
	</div>

	<mat-paginator
		[length]="resultsLength"
		[pageIndex]="0"
		[pageSize]="20"
		[pageSizeOptions]="[5, 10, 15, 20, 50]"
		[showFirstLastButtons]="true">
	</mat-paginator>
</div>
