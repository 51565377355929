import {
	AfterViewInit,
	Component,
	ElementRef,
	inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
	BehaviorSubject,
	fromEvent as observableFromEvent,
	merge,
	of as observableOf,
} from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DeleteConfirmComponent, SharedService } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import { union } from 'lodash';
import { LABEL_MODULES, LABELS_TABLE_COLS } from './table-conf';
import { FormControl } from '@angular/forms';
import { LabelEditComponent } from '../../shared/label-edit/label-edit.component';
import { LabelDTO } from '../../model';

@Component({
	selector: 'ft-label-setting',
	templateUrl: './label-setting.component.html',
	styleUrls: ['./label-setting.component.scss'],
})
export class LabelSettingComponent implements OnInit, AfterViewInit {
	dataSource = new MatTableDataSource<LabelDTO>();
	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	filterChange = new BehaviorSubject('');
	editChange = new BehaviorSubject('');

	cols = union(
		LABELS_TABLE_COLS.map(it => it.value),
		['action']
	);

	displayedColumns: any[] = LABELS_TABLE_COLS;

	@ViewChild('filter', { static: true }) filter: ElementRef;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	labelModuleForm: FormControl = new FormControl<any>([]);
	labelModules = LABEL_MODULES;

	private _shared = inject(SharedService);
	private _dialog = inject(MatDialog);

	ngOnInit() {
		this.filter.nativeElement.focus();
	}

	ngAfterViewInit() {
		observableFromEvent(this.filter.nativeElement, 'keyup')
			.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(() => {
				if (!this.dataSource) return;
				this.dataSource.filter = this.filter.nativeElement.value;
				this.filterChange.next(this.filter.nativeElement.value);
			});

		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		merge(
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.editChange,
			this.labelModuleForm.valueChanges
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					return this._shared.getLabels(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						`${this.filter.nativeElement.value}_${this.labelModuleForm.value.join('@')}`
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];
					return data['content'] as LabelDTO[];
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	editLabel(label: LabelDTO) {
		this._dialog
			.open(LabelEditComponent, { data: label, disableClose: true })
			.afterClosed()
			.subscribe(res => {
				if (res) {
					this.editChange.next(res);
				}
			});
	}

	deleteLabel(label: LabelDTO) {
		this._dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok) {
					this._shared.deleteLabel(label.id).subscribe(res => {
						if (res) {
							this.editChange.next(null);
						}
					});
				}
			});
	}

	getLabelModuleColor(module: string): string {
		switch (module) {
			case 'REPORT':
				return '#0b9eec';
			case 'APPOINTMENT':
				return '#f58b00';
			case 'PATIENT':
				return '#ff00b2';
			default:
				return '#00c53a';
		}
	}
}
