import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog';
import { SettingService } from '../../setting.service';
import { SharedService } from '../../../shared';
import { assign } from 'lodash';
import { AetFullDTO } from '../../../model';

@Component({
	selector: 'ft-aet-edit',
	templateUrl: './aet-edit.component.html',
	styleUrls: ['./aet-edit.component.scss'],
})
export class AetEditComponent implements OnInit {
	form: FormGroup;

	technicians: any[];
	modalities: any[];
	rooms: any[] = [];

	color: string = '#7bd7f4';

	constructor(
		@Inject(MAT_DIALOG_DATA) public aetId: any,
		private dialogRef: MatDialogRef<AetEditComponent>,
		private settingService: SettingService,
		private sharedService: SharedService,
		private dialog: MatDialog,
		private fb: FormBuilder
	) {
		this.form = this.fb.group(
			assign(new AetFullDTO(), {
				modalityId: ['', Validators.required],
				defaultTechnicianId: ['', Validators.required],
				room: ['', Validators.required],
			})
		);

		if (this.aetId)
			this.settingService
				.getAet(this.aetId)
				.subscribe(aet => this.form.patchValue(aet));
	}

	onSave(aet) {
		assign(aet, {
			color: this.color,
		});

		this.settingService.saveAet(aet).subscribe(res => {
			assign(res, {
				_room: res.room.name,
			});

			this.dialogRef.close(res);
		});
	}

	getTechnicians() {
		this.sharedService.getTechnicians().subscribe(data => {
			this.technicians = data;
		});
	}

	getModalities() {
		this.sharedService.getModalities().subscribe(data => {
			this.modalities = data;
		});
	}

	getRooms() {
		this.sharedService.getRooms().subscribe(data => {
			this.rooms = data;
		});
	}

	ngOnInit() {
		this.getTechnicians();
		this.getModalities();
		this.getRooms();
	}
}
