import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '../../shared';
import { FtBox } from '../../model';

@Component({
	selector: 'ft-box-edit',
	templateUrl: './box-edit.component.html',
	styleUrls: ['./box-edit.component.scss'],
})
export class BoxEditComponent implements OnInit {
	form: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: FtBox,
		private service: SharedService,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<BoxEditComponent>
	) {
		this.form = this.fb.group({
			id: 0,
			name: ['', Validators.required],
			description: '',
			deleted: false,
		});
	}

	ngOnInit() {
		if (this.data && this.data.id !== 0) {
			this.form.patchValue(this.data);
		}
	}

	saveData(data) {
		this.service.saveBox(data).subscribe(res => this.dialogRef.close(res));
	}
}
