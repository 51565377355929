<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
	<mat-icon fontIcon="mdi-file-table" fontSet="mdi"></mat-icon>
	<h3>{{ 'STAFF_CONTRACT' | translate }}</h3>

	<span class="fx-grow-1"></span>
	<button mat-button (click)="selection = !selection">
		<mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
		{{ 'ADD_EXCEPTION' | translate }}
	</button>
</mat-toolbar>

<div class="fx-layout-row fx-gap-8">
	<div
		style="padding: 12px; height: calc(100vh - 124px)"
		class="fx-grow-1 fx-overflow-auto fx-layout-column fx-content-space-between fx-items-stretch"
		[formGroup]="form">
		<div class="fx-layout-row fx-gap-4">
			<mat-form-field>
				<mat-label>{{ 'NAME' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'NAME' | translate"
					formControlName="name"
					#name />
			</mat-form-field>

			<mat-form-field>
				<mat-label>{{ 'FEE_TYPE' | translate }}</mat-label>
				<mat-select
					placeholder="{{ 'FEE_TYPE' | translate }}"
					formControlName="feeType">
					@for (item of feeTypes; track item) {
						<mat-option [value]="item">
							{{ item | translate }}
						</mat-option>
					}
				</mat-select>
			</mat-form-field>

			@if (form.get('feeType').value !== 'EXAM') {
				<mat-form-field>
					<mat-label>{{ 'FEE' | translate }}</mat-label>
					<input
						type="number"
						matInput
						[placeholder]="'FEE' | translate"
						formControlName="fee" />
				</mat-form-field>
			}
		</div>

		@if (form.get('feeType').value === 'EXAM') {
			<div class="fx-layout-row fx-content-start fx-items-center">
				<mat-form-field>
					<mat-label>{{ 'PART_TYPE' | translate }}</mat-label>
					<mat-select
						placeholder="{{ 'PART_TYPE' | translate }}"
						formControlName="partType"
						[disableOptionCentering]="true">
						@for (option of partTypes; track option) {
							<mat-option [value]="option">
								{{
									option.startsWith('AMOUNT')
										? (option
											| translate
											: { currency: currencyFormat })
										: (option | translate)
								}}
							</mat-option>
						}
					</mat-select>
				</mat-form-field>
				<!--if part amount-->
				@if (form.get('partType').value === 'AMOUNT') {
					<mat-form-field>
						<mat-label>{{ 'PART' | translate }}</mat-label>
						<input
							type="number"
							matInput
							[placeholder]="'PART' | translate"
							formControlName="partPrice" />
					</mat-form-field>
				}

				<!--if part percentage-->

				@if (form.get('partType').value === 'PERCENTAGE') {
					<mat-form-field class="left-padded">
						<mat-label>{{
								'CALCULATION_BASE' | translate
							}}
						</mat-label>
						<mat-select
							placeholder="{{ 'CALCULATION_BASE' | translate }}"
							formControlName="calculationBase"
							[disableOptionCentering]="true">
							@for (item of calculationBases; track item) {
								<mat-option [value]="item">
									{{ item | translate }}
								</mat-option>
							}
						</mat-select>
					</mat-form-field>

					<mat-form-field class="left-padded">
						<mat-label>{{
								'PART_PERCENTAGE' | translate
							}}
						</mat-label>
						<input
							type="number"
							matInput
							[placeholder]="'PART_PERCENTAGE' | translate"
							formControlName="partPercentage" />
					</mat-form-field>

					<!--if base exam paid-->
					@if (form.get('calculationBase').value === 'PAID_AMOUNT') {
						<mat-form-field class="left-padded">
							<mat-label>{{
									'MIN_PRICE'
										| translate: { currency: currencyFormat }
								}}
							</mat-label>
							<input
								type="number"
								matInput
								[placeholder]="'MIN_PRICE' | translate"
								formControlName="minPrice" />
						</mat-form-field>
						<mat-form-field class="left-padded">
							<mat-label>{{
									'MAX_PRICE'
										| translate: { currency: currencyFormat }
								}}
							</mat-label>
							<input
								type="number"
								matInput
								[placeholder]="'MAX_PRICE' | translate"
								formControlName="maxPrice" />
						</mat-form-field>
					}
				}
			</div>
		}

		<div class="fx-layout-column fx-fill-width">
			@if (form.get('examTypes')['controls'].length !== 0) {
				<div class="field-title">
					{{ 'EXAM_TYPE_EXCEPTIONS' | translate }}
				</div>
			}
			@for (item of form.get('examTypes')['controls'];
				let i = $index;
				track item) {
				<div
					class="tree-card fx-content-space-between fx-items-center"
					formArrayName="examTypes">
					<div
						class="fx-layout-row fx-content-start fx-items-center"
						[formGroupName]="i"
						style="width: 100%">
						<div style="width: 20%">
							{{ item.get('name').value }}
						</div>
						<div
							class="fx-grow-1 fx-layout-row fx-content-start fx-items-center">
							<mat-form-field subscriptSizing="dynamic">
								<mat-label>{{
										'PART_TYPE' | translate
									}}
								</mat-label>
								<mat-select
									placeholder="{{ 'PART_TYPE' | translate }}"
									formControlName="partType">
									@for (option of partTypes; track option) {
										<mat-option [value]="option">
											{{
												option.startsWith('AMOUNT')
													? (option
														| translate
														: {
															currency:
															currencyFormat
														})
													: (option | translate)
											}}
										</mat-option>
									}
								</mat-select>
							</mat-form-field>
							<!--if part amount-->
							@if (item.get('partType').value === 'AMOUNT') {
								<mat-form-field subscriptSizing="dynamic">
									<mat-label>{{
											'PART' | translate
										}}
									</mat-label>
									<input
										type="number"
										matInput
										[placeholder]="'PART' | translate"
										formControlName="partPrice" />
								</mat-form-field>
							}

							<!--if part percentage-->
							@if (item.get('partType').value === 'PERCENTAGE') {
								<mat-form-field
									subscriptSizing="dynamic"
									class="left-padded">
									<mat-label>{{
											'CALCULATION_BASE' | translate
										}}
									</mat-label>
									<mat-select
										placeholder="{{
											'CALCULATION_BASE' | translate
										}}"
										formControlName="calculationBase">
										@for (option of calculationBases;
											track option) {
											<mat-option [value]="option">
												{{ option | translate }}
											</mat-option>
										}
									</mat-select>
								</mat-form-field>

								<mat-form-field
									subscriptSizing="dynamic"
									class="left-padded">
									<mat-label>{{
											'PART_PERCENTAGE' | translate
										}}
									</mat-label>
									<input
										type="number"
										matInput
										[placeholder]="
											'PART_PERCENTAGE' | translate
										"
										formControlName="partPercentage" />
								</mat-form-field>

								<!--if base exam paid-->
								@if (item.get('calculationBase').value ===
								'PAID_AMOUNT') {
									<mat-form-field
										subscriptSizing="dynamic"
										class="left-padded">
										<mat-label>{{
												'MIN_PRICE' | translate
											}}
										</mat-label>
										<input
											type="number"
											matInput
											[placeholder]="
												'MIN_PRICE' | translate
											"
											formControlName="minPrice" />
									</mat-form-field>
									<mat-form-field
										subscriptSizing="dynamic"
										class="left-padded">
										<mat-label>{{
												'MAX_PRICE' | translate
											}}
										</mat-label>
										<input
											type="number"
											matInput
											[placeholder]="
												'MAX_PRICE' | translate
											"
											formControlName="maxPrice" />
									</mat-form-field>
								}
							}
						</div>
					</div>
				</div>
			}
		</div>

		<div class="fx-layout-column fx-fill-width">
			@if (form.get('procedureCodes')['controls'].length !== 0) {
				<div class="field-title">
					{{ 'PROCEDURE_CODE_EXCEPTIONS' | translate }}
				</div>
			}
			@for (item of form.get('procedureCodes')['controls'];
				let i = $index;
				track item) {
				<div
					class="tree-card fx-content-space-between fx-items-center"
					formArrayName="procedureCodes">
					<div
						[formGroupName]="i"
						class="fx-fill-width fx-layout-row fx-content-start fx-items-center">
						<div style="width: 20%">
							{{ item.get('name').value }}
						</div>
						<div
							class="fx-grow-1 fx-layout-row fx-content-start fx-items-center">
							<mat-form-field subscriptSizing="dynamic">
								<mat-label>{{
										'PART_TYPE' | translate
									}}
								</mat-label>
								<mat-select
									placeholder="{{ 'PART_TYPE' | translate }}"
									formControlName="partType">
									@for (option of partTypes; track option) {
										<mat-option [value]="option">
											{{
												option.startsWith('AMOUNT')
													? (option
														| translate
														: {
															currency:
															currencyFormat
														})
													: (option | translate)
											}}
										</mat-option>
									}
								</mat-select>
							</mat-form-field>
							<!--if part amount-->
							@if (item.get('partType').value === 'AMOUNT') {
								<mat-form-field subscriptSizing="dynamic">
									<mat-label>{{
											'PART' | translate
										}}
									</mat-label>
									<input
										type="number"
										matInput
										[placeholder]="'PART' | translate"
										formControlName="partPrice" />
								</mat-form-field>
							}

							<!--if part percentage-->
							@if (item.get('partType').value === 'PERCENTAGE') {
								<mat-form-field
									subscriptSizing="dynamic"
									class="left-padded">
									<mat-label>{{
											'CALCULATION_BASE' | translate
										}}
									</mat-label>
									<mat-select
										placeholder="{{
											'CALCULATION_BASE' | translate
										}}"
										formControlName="calculationBase">
										@for (option of calculationBases;
											track option) {
											<mat-option [value]="option">
												{{ option | translate }}
											</mat-option>
										}
									</mat-select>
								</mat-form-field>

								<mat-form-field
									subscriptSizing="dynamic"
									class="left-padded">
									<mat-label>{{
											'PART_PERCENTAGE' | translate
										}}
									</mat-label>
									<input
										type="number"
										matInput
										[placeholder]="
											'PART_PERCENTAGE' | translate
										"
										formControlName="partPercentage" />
								</mat-form-field>

								<!--if base exam paid-->
								@if (item.get('calculationBase').value ===
								'PAID_AMOUNT') {
									<mat-form-field
										subscriptSizing="dynamic"
										class="left-padded">
										<mat-label>{{
												'MIN_PRICE' | translate
											}}
										</mat-label>
										<input
											type="number"
											matInput
											[placeholder]="
												'MIN_PRICE' | translate
											"
											formControlName="minPrice" />
									</mat-form-field>
									<mat-form-field
										subscriptSizing="dynamic"
										class="left-padded">
										<mat-label>{{
												'MAX_PRICE' | translate
											}}
										</mat-label>
										<input
											type="number"
											matInput
											[placeholder]="
												'MAX_PRICE' | translate
											"
											formControlName="maxPrice" />
									</mat-form-field>
								}
							}
						</div>
					</div>
				</div>
			}
		</div>

		<span class="fx-grow-1"></span>
		<div
			class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-4"
			style="margin-top: 12px">
			<button mat-raised-button color="warn" (click)="cancel()">
				{{ 'CANCEL' | translate }}
			</button>
			<button
				mat-raised-button
				color="primary"
				(click)="onSave(form.value)"
				[disabled]="!form.valid">
				{{ 'SAVE' | translate }}
			</button>
		</div>
	</div>

	<!--tree exams selection-->
	<div [ngClass]="{ selection: true, visible: selection }">
		<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
			<!-- This is the tree node template for leaf nodes -->
			<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
				<button mat-icon-button disabled>&nbsp;</button>
				<mat-checkbox
					[checked]="procedureCodeSelected(node)"
					(change)="selectProcedureCode(node)"
				>{{ node.name }}
				</mat-checkbox>
			</mat-tree-node>

			<!-- This is the tree node template for expandable nodes -->
			<mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
				<div class="mat-tree-node">
					<button mat-icon-button matTreeNodeToggle>
						<mat-icon
							fontSet="mdi"
							[fontIcon]="
								treeControl.isExpanded(node)
									? 'mdi-chevron-down'
									: 'mdi-chevron-right'
							"></mat-icon>
					</button>
					<mat-checkbox
						[checked]="examTypeSelected(node)"
						[indeterminate]="examTypeChildrenPartial(node)"
						(change)="selectExamType(node)"
					>{{ node.name }}
					</mat-checkbox>
				</div>
				<div
					[class.example-tree-invisible]="
						!treeControl.isExpanded(node)
					">
					<ng-container matTreeNodeOutlet></ng-container>
				</div>
			</mat-nested-tree-node>
		</mat-tree>
	</div>
</div>
