<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
	<mat-icon fontIcon="mdi-message" fontSet="mdi"></mat-icon>
	<h3>{{ 'SMS_TEMPLATES' | translate }}</h3>
</mat-toolbar>

<div
	class="relative fx-layout-column-nowrap fx-content-center fx-items-center ft-setting-card-width"
	style="padding-top: 24px">
	<mat-accordion>
		@for (sms of smsTemplates; track sms) {
			<mat-expansion-panel (opened)="selectSmsTemplate(sms)">
				<mat-expansion-panel-header>
					<mat-panel-title [style.max-width.px]="160">
						<mat-chip-listbox>
							<mat-chip-option
								style="font-weight: bold"
								selectable="false"
								[style.background-color]="
									sms.is_default ? '#e8a959' : ''
								"
								>{{ sms.title }}</mat-chip-option
							>
						</mat-chip-listbox>
					</mat-panel-title>
					<mat-panel-description>
						<p
							style="
								max-width: 460px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							">
							{{ sms.body }}
						</p>
					</mat-panel-description>
				</mat-expansion-panel-header>
				<div [formGroup]="smsTemplateForm" class="fx-layout-column">
					<mat-form-field>
						<mat-label>{{ 'TITLE' | translate }}</mat-label>
						<input
							[placeholder]="'TITLE' | translate"
							formControlName="title"
							matInput
							type="text" />
					</mat-form-field>

					<mat-form-field>
						<mat-label>{{ 'MESSAGE' | translate }}</mat-label>
						<textarea
							[placeholder]="'MESSAGE' | translate"
							formControlName="body"
							id="sms-body"
							matInput></textarea>
					</mat-form-field>

					<mat-slide-toggle
						formControlName="is_default"
						color="primary"
						labelPosition="after"
						style="font-size: 12px; font-weight: bold">
						{{ 'DEFAULT_SMS' | translate }}
					</mat-slide-toggle>

					<div
						[matTooltip]="'CLICK_TO_INSERT' | translate"
						class="variables">
						* Variables:
						<span (click)="copyVariable($event)">PATIENT_NAME</span>
						- <span (click)="copyVariable($event)">EXAM</span> -
						<span (click)="copyVariable($event)">DATE</span>
					</div>

					<div
						class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-4"
						style="margin-top: 24px">
						<button
							(click)="deleteSmsTemplate(sms)"
							color="warn"
							mat-button>
							{{ 'DELETE' | translate }}
						</button>
						<button
							(click)="saveSmsTemplate(smsTemplateForm.value)"
							[disabled]="smsTemplateForm.invalid"
							color="primary"
							mat-raised-button>
							{{ 'SAVE' | translate }}
						</button>
					</div>
				</div>
			</mat-expansion-panel>
		}
	</mat-accordion>

	<button
		mat-fab
		[matTooltip]="'ADD_NEW' | translate"
		(click)="addSmsTemplate()"
		style="position: fixed; bottom: 46px; right: 56px">
		<mat-icon
			class="add-icon-gradient"
			fontIcon="mdi-plus"
			fontSet="mdi"></mat-icon>
	</button>
</div>
