import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

@Component({
	selector: 'ft-forms-builder',
	templateUrl: './forms-builder.component.html',
	styleUrls: ['./forms-builder.component.scss'],
})
export class FormsBuilderComponent {
	form = new FormGroup({});
	model: any = {};
	options: FormlyFormOptions = {};

	fields: FormlyFieldConfig[] = [
		{
			fieldGroupClassName: 'fx-layout-row fx-gap-8',
			fieldGroup: [
				{
					type: 'input',
					key: 'firstName',
					props: {
						label: 'First Name',
					},
				},
				{
					type: 'input',
					key: 'lastName',
					props: {
						label: 'Last Name',
					},
					expressions: {
						'props.disabled': '!model.firstName',
					},
				},
			],
		},
		{
			template: 'Address',
			className: 'fw-600 fz-16 fx-padding-16',
		},
		{
			fieldGroupClassName: 'fx-layout-row fx-gap-8',
			fieldGroup: [
				{
					type: 'input',
					key: 'street',
					props: {
						label: 'Street',
					},
				},
				{
					type: 'input',
					key: 'cityName',
					props: {
						label: 'City',
					},
				},
				{
					type: 'input',
					key: 'zip',
					props: {
						type: 'number',
						label: 'Zip',
						max: 99999,
						min: 0,
						pattern: '\\d{5}',
					},
				},
			],
		},
		{
			type: 'input',
			key: 'otherInput',
			props: {
				label: 'Other Input',
			},
		},
		{
			template: 'Others',
			className: 'fw-600 fz-16 fx-padding-16',
		},
		{
			className: 'mat-primary',
			type: 'checkbox',
			key: 'otherToo',
			props: {
				label: 'Other Checkbox',
				className: 'mat-primary',
			},
		},
	];
	onSubmit(model) {
		console.log(model);
	}
}
