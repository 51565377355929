<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
	<mat-icon fontIcon="mdi-drawing" fontSet="mdi"></mat-icon>
	<h3>{{ 'MODALITIES' | translate }}</h3>
</mat-toolbar>

<div
	class="relative fx-layout-column-nowrap fx-content-center fx-items-center ft-setting-card-width"
	style="padding-top: 24px">
	<mat-accordion>
		@for (modality of modalities; track modality) {
			<mat-expansion-panel (opened)="selectModality(modality)">
				<mat-expansion-panel-header>
					<mat-panel-title [style.max-width.px]="100">
						<mat-chip-listbox>
							<mat-chip-option
								selectable="false"
								[style.background-color]="modality.color"
								>{{ modality.value }}</mat-chip-option
							>
						</mat-chip-listbox>
					</mat-panel-title>
					<mat-panel-description>
						{{ modality.description }}
					</mat-panel-description>
				</mat-expansion-panel-header>

				<form [formGroup]="modalityForm" class="fx-layout-column">
					<mat-form-field>
						<mat-label>{{ 'VALUE' | translate }}</mat-label>
						<input
							matInput
							placeholder="{{ 'VALUE' | translate }}"
							formControlName="value" />
					</mat-form-field>

					<mat-form-field>
						<mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
						<textarea
							matInput
							placeholder="{{ 'DESCRIPTION' | translate }}"
							formControlName="description"></textarea>
					</mat-form-field>

					<mat-form-field>
						<mat-label>{{
							'DEFAULTAETITLE' | translate
						}}</mat-label>
						<mat-select
							placeholder="{{ 'DEFAULTAETITLE' | translate }}"
							formControlName="defaultAETitle">
							@for (aet of aets; track aet) {
								<mat-option [value]="aet.title">
									{{ aet.title }}
								</mat-option>
							}
						</mat-select>
					</mat-form-field>

					<div class="ft-color">
						<div>{{ 'COLOR' | translate }}:</div>
						<div
							class="color-area"
							[(colorPicker)]="currentModalityColor"
							[style.background]="currentModalityColor"></div>

						<span fxFlex></span>
						<button
							mat-raised-button
							color="primary"
							(click)="updateModality(modalityForm.value)">
							{{ 'SAVE' | translate }}
						</button>
					</div>
				</form>
			</mat-expansion-panel>
		}
	</mat-accordion>
</div>
