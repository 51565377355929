export const UNV_TABLE_CONF = [
	{
		label: 'id',
		header: 'ID',
		value: 'id',
		type: 'string',
		table: 'BOTH',
		hidden: true,
	},
	{
		label: 'title',
		header: 'TITLE',
		value: 'title',
		type: 'string',
		table: 'HOLIDAY',
		hidden: false,
	},
	{
		label: 'startDate',
		header: 'START_DATE',
		value: 'startDate',
		type: 'date',
		table: 'BOTH',
		hidden: false,
	},
	{
		label: 'endDate',
		header: 'END_DATE',
		value: 'endDate',
		type: 'date',
		table: 'BOTH',
		hidden: false,
	},
	{
		label: 'employeeName',
		header: 'EMPLOYEE',
		value: 'employeeName',
		type: 'string',
		table: 'LEAVE',
		hidden: false,
	},
	{
		label: 'managerName',
		header: 'MANAGER',
		value: 'managerName',
		type: 'string',
		table: 'LEAVE',
		hidden: false,
	},
	{
		label: 'status',
		header: 'STATUS',
		value: 'status',
		type: 'status',
		table: 'LEAVE',
		hidden: false,
	},
	{
		label: 'notes',
		header: 'NOTES',
		value: 'notes',
		type: 'string',
		table: 'LEAVE',
		hidden: false,
	},
];
