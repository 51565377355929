<mat-toolbar class="dialog-toolbar">
	<mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
	<h3 style="padding-left: 6px">{{ 'PROFILE' | translate }}</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button [matDialogClose]="null" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div style="max-height: 100vh; padding-inline: 8px">
	<div
		class="fx-layout-row-nowrap fx-content-space-between fx-items-stretch"
		[formGroup]="profileForm">
		<div class="profile-side fx-layout-column">
			<mat-form-field class="fx-fill-width">
				<mat-label>{{ 'NAME' | translate }}</mat-label>
				<input
					matInput
					[placeholder]="'NAME' | translate"
					formControlName="name" />
			</mat-form-field>

			<mat-form-field class="fx-fill-width">
				<mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
				<textarea
					matInput
					[placeholder]="'DESCRIPTION' | translate"
					formControlName="description"></textarea>
			</mat-form-field>

			<section style="margin-top: 20px">
				<mat-checkbox color="primary" formControlName="admin">
					{{ 'ADMIN' | translate }}
				</mat-checkbox>
			</section>

			<section style="margin-top: 20px">
				<mat-checkbox color="primary" formControlName="managePayment">
					{{ 'MANAGE_PAYMENT' | translate }}
				</mat-checkbox>
			</section>

			<section style="margin-top: 20px">
				<mat-checkbox
					color="primary"
					formControlName="canDeletePayment">
					{{ 'CAN_DELETE_PAYMENT' | translate }}
				</mat-checkbox>
			</section>

			<div style="font-size: 14px; margin: 24px 0 12px">
				{{ 'DEFAULT_ROUTE' | translate }}
			</div>
			<mat-radio-group
				class="fx-layout-column"
				formControlName="defaultRoute">
				@for (
					route of [
						'patient',
						'scheduler',
						'reporting',
						'workflow',
						'setting'
					];
					track route
				) {
					<mat-radio-button class="fx-grow-1" [value]="route">
						{{ route | translate }}
					</mat-radio-button>
				}
			</mat-radio-group>
		</div>

		<div class="fx-grow-1 grid-layout fx-layout-row fx-gap-8">
			@for (module of modules; track module) {
				<div class="module-layout {{ module.module }} fx-grow-1">
					<div class="module-header">
						<mat-slide-toggle
							color="primary"
							formControlName="{{ module.module }}">
							{{ module.name | translate }}
						</mat-slide-toggle>
					</div>
					@for (field of module.fields; track field) {
						<div
							class="fx-layout-row fx-content-space-between fx-items-center">
							<div
								style="
									font-size: 14px;
									max-width: 180px;
									font-weight: 500;
									font-style: italic;
								">
								{{ field | translate }}
							</div>
							<mat-radio-group formControlName="{{ field }}">
								@for (
									level of ['ALL', 'OWN', 'NONE'];
									track level
								) {
									<mat-radio-button
										color="primary"
										class="fx-grow-1"
										[value]="level">
										{{ level | translate }}
									</mat-radio-button>
								}
							</mat-radio-group>
						</div>
					}
				</div>
			}
		</div>
	</div>
</div>

<mat-dialog-actions align="end">
	<button [mat-dialog-close]="null" color="warn" mat-raised-button>
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="onSave(profileForm.value)"
		[disabled]="profileForm.invalid"
		color="primary"
		mat-raised-button>
		{{ 'SAVE' | translate }}
	</button>
</mat-dialog-actions>
