<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
	<mat-icon fontIcon="mdi-order-bool-descending" fontSet="mdi"></mat-icon>
	<h3>{{ 'DEFAULT_VALUES' | translate }}</h3>
</mat-toolbar>

@if (defaultValues) {
	<div
		class="fx-padding-16 relative fx-overflow-auto"
		style="height: calc(100% - 72px)">
		<div class="values-section">
			<h4>{{ 'PATIENT' | translate }}</h4>
			<div class="values-grid">
				<mat-form-field>
					<mat-label>{{ 'DEFAULT_TITLE' | translate }}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultTitle"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{ 'DEFAULT_TITLE' | translate }}">
						@for (title of titles; track title) {
							<mat-option [value]="title.value">
								{{ title.value }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultTitle) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultTitle')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>{{ 'DEFAULT_GENDER' | translate }}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultGender"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{ 'DEFAULT_GENDER' | translate }}">
						@for (gender of genders; track gender) {
							<mat-option [value]="gender.value">
								{{ gender.description }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultGender) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultGender')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>{{
						'DEFAULT_MARITAL_STATUS' | translate
					}}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultMaritalStatus"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{
							'DEFAULT_MARITAL_STATUS' | translate
						}}">
						@for (ms of maritalStatuses; track ms) {
							<mat-option [value]="ms.value">
								{{ ms.description }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultMaritalStatus) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultMaritalStatus')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>{{
						'DEFAULT_RELATION_WITH_INSURED' | translate
					}}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultRelationWithInsured"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{
							'DEFAULT_RELATION_WITH_INSURED' | translate
						}}">
						@for (
							state of ['HIMSELF', 'PARTNER', 'CHILD'];
							track state
						) {
							<mat-option [value]="state">
								{{ state | translate }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultRelationWithInsured) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultRelationWithInsured')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>
			</div>
		</div>

		<div class="values-section">
			<h4>{{ 'LOCATION' | translate }}</h4>
			<div class="values-grid">
				<mat-form-field>
					<mat-label>{{ 'DEFAULT_COUNTRY' | translate }}</mat-label>
					<input
						matInput
						[(ngModel)]="defaultValues.defaultCountry"
						[ngModelOptions]="{ standalone: true }"
						(blur)="saveDefaultValues(defaultValues)"
						[placeholder]="'DEFAULT_COUNTRY' | translate" />
				</mat-form-field>
				<mat-form-field>
					<mat-label>{{ 'DEFAULT_CITY' | translate }}</mat-label>
					<input
						matInput
						[(ngModel)]="defaultValues.defaultCity"
						[ngModelOptions]="{ standalone: true }"
						(blur)="saveDefaultValues(defaultValues)"
						[placeholder]="'DEFAULT_CITY' | translate" />
				</mat-form-field>
			</div>
		</div>

		<div class="values-section">
			<h4>{{ 'PROCEDURE_CODE' | translate }}</h4>
			<div class="values-grid">
				<mat-form-field>
					<mat-label>{{ 'DEFAULT_PRIORITY' | translate }}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultPriority"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{ 'DEFAULT_PRIORITY' | translate }}">
						@for (priority of priorities; track priority) {
							<mat-option [value]="priority.value">
								{{ priority.value }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultPriority) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultPriority')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>

				<mat-form-field>
					<mat-label>{{
						'DEFAULT_PATIENT_CLASS' | translate
					}}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultPatientClass"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{ 'DEFAULT_PATIENT_CLASS' | translate }}">
						@for (cls of patientClasses; track cls) {
							<mat-option [value]="cls.value">
								{{ cls.description }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultPatientClass) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultPatientClass')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>{{
						'DEFAULT_CONFIDENTIALITY' | translate
					}}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultConfidentiality"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{
							'DEFAULT_CONFIDENTIALITY' | translate
						}}">
						@for (
							confidentiality of confidentialities;
							track confidentiality
						) {
							<mat-option [value]="confidentiality.value">
								{{ confidentiality.description }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultConfidentiality) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultConfidentiality')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>

				<mat-form-field>
					<mat-label>{{
						'DEFAULT_PERFORMING_PHYSICIAN' | translate
					}}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultPerformingPhysician"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{
							'DEFAULT_PERFORMING_PHYSICIAN' | translate
						}}">
						@for (physician of physicians; track physician) {
							<mat-option [value]="physician.id">
								{{ physician.fullName }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultPerformingPhysician) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultPerformingPhysician')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>{{
						'DEFAULT_TECHNICIAN' | translate
					}}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultTechnician"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{ 'DEFAULT_TECHNICIAN' | translate }}">
						@for (technician of technicians; track technician) {
							<mat-option [value]="technician.id">
								{{ technician.fullName }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultTechnician) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultTechnician')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>
			</div>
		</div>

		<div class="values-section">
			<h4>{{ 'PAYMENT_AND_INSURANCE' | translate }}</h4>
			<div class="values-grid">
				<mat-form-field>
					<mat-label>{{
						'DEFAULT_PAYMENT_MODALITY' | translate
					}}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultPaymentModality"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{
							'DEFAULT_PAYMENT_MODALITY' | translate
						}}">
						@for (
							state of [
								'PATIENT',
								'INSURED_PATIENT',
								'THIRD_PARTY_PAYMENT',
								'PEC',
								'RAMED'
							];
							track state
						) {
							<mat-option [value]="state">
								{{ state | translate }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultPaymentModality) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultPaymentModality')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>

				<mat-form-field>
					<mat-label>{{
						'DEFAULT_PAYMENT_METHOD' | translate
					}}</mat-label>
					<mat-select
						(selectionChange)="saveDefaultValues(defaultValues)"
						[(ngModel)]="defaultValues.defaultPaymentMethod"
						[ngModelOptions]="{ standalone: true }"
						placeholder="{{
							'DEFAULT_PAYMENT_METHOD' | translate
						}}">
						@for (method of paymentMethods; track method) {
							<mat-option [value]="method.value">
								{{ method.value }}
							</mat-option>
						}
					</mat-select>
					@if (defaultValues.defaultPaymentMethod) {
						<button
							(click)="
								$event.stopImmediatePropagation();
								clearInput('defaultPaymentMethod')
							"
							mat-icon-button
							color="warn"
							matSuffix>
							<mat-icon
								fontIcon="mdi-close"
								fontSet="mdi"></mat-icon>
						</button>
					}
				</mat-form-field>
			</div>
		</div>
	</div>
}
