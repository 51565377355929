<mat-toolbar class="fx-height-42 fz-16" color="primary">
	<mat-icon fontSet="mdi" fontIcon="mdi-credit-card"></mat-icon>
	<h3 class="fz-16" style="padding-left: 4px">
		{{ 'BILLING_CODE' | translate }}
	</h3>
	<span class="fx-grow-1"></span>
	<button mat-icon-button matDialogClose="" tabindex="-1">
		<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
	</button>
</mat-toolbar>

<div class="fx-padding-24">
	<form [formGroup]="billingCodeForm" class="fx-layout-column">
		<mat-form-field class="fx-fill-width">
			<mat-label>{{ 'CODE' | translate }}</mat-label>
			<input
				matInput
				placeholder="{{ 'CODE' | translate }}"
				formControlName="code"
				required />
		</mat-form-field>

		<mat-form-field class="fx-fill-width">
			<mat-label>{{ 'PRICE' | translate }}</mat-label>
			<input
				matInput
				type="number"
				placeholder="{{ 'PRICE' | translate }}"
				formControlName="price" />
		</mat-form-field>

		<mat-form-field class="fx-fill-width">
			<mat-label>{{ 'CODE_NGAP' | translate }}</mat-label>
			<input
				matInput
				placeholder="{{ 'CODE_NGAP' | translate }}"
				formControlName="description" />
		</mat-form-field>
	</form>
</div>
<div matDialogActions align="end">
	<button color="warn" mat-raised-button matDialogClose="">
		{{ 'CANCEL' | translate }}
	</button>
	<button
		(click)="saveBillingCode(billingCodeForm.value)"
		[disabled]="billingCodeForm.invalid"
		color="primary"
		mat-raised-button>
		{{ 'SAVE' | translate }}
	</button>
</div>
