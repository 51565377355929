<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
	<mat-icon fontIcon="mdi-dna" fontSet="mdi"></mat-icon>
	<h3>{{ 'PATHOLOGIES' | translate }}</h3>
</mat-toolbar>

<div
	class="relative fx-layout-column-nowrap fx-content-center fx-items-center ft-setting-card-width"
	style="padding-top: 24px">
	<mat-accordion>
		@for (category of categories; track category) {
			<mat-expansion-panel (opened)="getPathologies(category)">
				<mat-expansion-panel-header>
					<mat-panel-title [style.max-width.px]="100">
						<mat-chip-listbox>
							<mat-chip-option
								(click)="
									$event.stopImmediatePropagation();
									editCategory(category)
								"
								selectable="false"
								>{{ category }}</mat-chip-option
							>
						</mat-chip-listbox>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<mat-nav-list>
					@for (pathology of pathologies; track pathology) {
						<mat-list-item>
							<p matListItemTitle>{{ pathology.value }}</p>
							<button
								mat-icon-button
								matListItemMeta
								(click)="editPathology(pathology)">
								<mat-icon
									fontIcon="mdi-pencil"
									fontSet="mdi"></mat-icon>
							</button>
						</mat-list-item>
					}
					<div>
						<mat-form-field [formGroup]="pathologyForm">
							<input
								matInput
								placeholder="{{
									'NEW_PATHOLOGY' | translate
								}}..."
								formControlName="value"
								(keydown)="
									onSavePathology(
										$event,
										pathologyForm.value,
										category
									)
								" />
						</mat-form-field>
					</div>
				</mat-nav-list>
			</mat-expansion-panel>
		}
	</mat-accordion>

	<mat-form-field class="new-category-input">
		<input
			matInput
			[placeholder]="'ADD_NEW' | translate"
			[formControl]="categoryControl"
			(keydown)="onEnter($event)" />
	</mat-form-field>
</div>
