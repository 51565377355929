export const USER_TABLE_CONF = [
	{
		label: 'id',
		header: 'ID',
		value: 'id',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: true,
		searchable: true,
		required: true,
	},
	{
		label: 'canViewConfidentialData',
		header: 'CAN_VIEW_CONFIDENTIAL_DATA',
		value: 'canViewConfidentialData',
		type: 'boolean',
		unit: null,
		defaultValue: false,
		sortable: true,
		hidden: true,
		searchable: true,
		required: true,
	},
	{
		label: 'fullName',
		header: 'FULL_NAME',
		value: 'fullName',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
	{
		label: 'username',
		header: 'USERNAME',
		value: 'username',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
	{
		label: 'clinicalID',
		header: 'CLINICALID',
		value: 'clinicalID',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
	{
		label: 'prof',
		header: 'PROFILE',
		value: 'profileId',
		type: 'profile',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
	{
		label: 'funct',
		header: 'FUNCTION',
		value: 'functionId',
		type: 'function',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
	{
		label: 'color',
		header: 'COLOR',
		value: 'color',
		type: 'color',
		unit: 'color',
		defaultValue: '#F5F5F5',
		sortable: true,
		hidden: false,
		searchable: true,
		required: true,
	},
];
