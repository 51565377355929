import { Component, OnInit } from '@angular/core';
import {
	CUSTOM_TABLE_CONF,
	DICOM_TABLE_CONF,
	HL7_MESSAGES,
	HL7_SERVICE_TYPES,
	HL7_TABLE_CONF,
} from './table-conf';
import { SettingService } from '../setting.service';
import { union } from 'lodash';
import { FormBuilder } from '@angular/forms';

class RemoteServer {
	constructor(
		public id?: number,
		public hostname?: string,
		public port?: number,
		public title?: string,
		public activated?: boolean,
		public apiEndpoint?: string,
		public retrieveMethod: string = 'C-MOVE',
		public queryAttribute: string = 'studyInstanceUID',
		public queryRangeInMinutes: number = 180,
		public refreshRateInSeconds: number = 30,
		public label?: string,
		public supportedMessages?: string,
		public serviceType?: string,
		public principal?: boolean
	) {}
}

type ServerType = 'DICOM' | 'HL7' | 'CUSTOM';

@Component({
	selector: 'ft-peers-setting',
	templateUrl: './peers-setting.component.html',
	styleUrls: ['./peers-setting.component.scss'],
})
export class PeersSettingComponent implements OnInit {
	dicomColumns = union(
		DICOM_TABLE_CONF.map(it => it.label).filter(it => it !== 'id'),
		['action']
	);
	hl7Columns = union(
		HL7_TABLE_CONF.map(it => it.label).filter(it => it !== 'id'),
		['action']
	);
	customColumns = union(
		CUSTOM_TABLE_CONF.map(it => it.label).filter(it => it !== 'id'),
		['action']
	);

	displayedColumns: any[] = DICOM_TABLE_CONF;
	displayedHl7Columns: any[] = HL7_TABLE_CONF;
	displayedCustomColumns: any[] = CUSTOM_TABLE_CONF;

	dicomServers: any[];
	hl7Servers: any[];
	customServers: any[];
	serverForm: any;

	serverType: ServerType;
	hl7ServiceTypes: string[] = HL7_SERVICE_TYPES;

	hl7Messages: string[] = HL7_MESSAGES;

	constructor(
		private _service: SettingService,
		private _fb: FormBuilder
	) {
		this.serverForm = this._fb.group(new RemoteServer());
	}

	ngOnInit(): void {
		this.getDicomServers();
		this.getHL7Servers();
		this.getCustomServers();
	}

	private getDicomServers() {
		this._service
			.getDicomServers()
			.subscribe(value => (this.dicomServers = value));
	}

	private getHL7Servers() {
		this._service
			.getHl7Servers()
			.subscribe(value => (this.hl7Servers = value));
	}

	private getCustomServers() {
		this._service
			.getCustomServers()
			.subscribe(value => (this.customServers = value));
	}

	createDicomServer(data: any) {
		this._service.createDicomServer(data).subscribe(value => {
			if (value) this.getDicomServers();
		});
	}

	createHl7Server(data: any) {
		data.supportedMessages = data.supportedMessages?.join(',');
		this._service.createHl7Server(data).subscribe(value => {
			if (value) this.getHL7Servers();
		});
	}

	createCustomServer(data: any) {
		this._service.createCustomServer(data).subscribe(value => {
			if (value) this.getCustomServers();
		});
	}

	deleteDicomServer(id: number) {
		this._service.deleteDicomServer(id).subscribe(ok => {
			if (ok) this.getDicomServers();
		});
	}

	deleteHl7Server(id: number) {
		this._service.deleteHl7Server(id).subscribe(ok => {
			if (ok) this.getHL7Servers();
		});
	}

	deleteCustomServer(id: number) {
		this._service.deleteCustomServer(id).subscribe(ok => {
			if (ok) this.getCustomServers();
		});
	}

	saveServer(value: any) {
		switch (this.serverType) {
			case 'CUSTOM':
				this.createCustomServer(value);
				break;
			case 'DICOM':
				this.createDicomServer(value);
				break;
			case 'HL7':
				this.createHl7Server(value);
				break;
		}

		this.serverForm.patchValue(null);
	}

	editServer(row: any) {
		if (this.serverType === 'HL7')
			row.supportedMessages = row.supportedMessages?.split(',');
		this.serverForm.patchValue(row);
	}

	emptyForm() {
		this.serverForm.patchValue(new RemoteServer());
	}
}
