import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingComponent } from './setting.component';
import { GeneralSettingComponent } from './general-setting/general-setting.component';
import { ProfileSettingComponent } from './profile-setting/profile-setting.component';
import { AetSettingComponent } from './aet-setting/aet-setting.component';
import { UsersSettingComponent } from './users-setting/users-setting.component';
import { ProcedureCodeSettingComponent } from './procedure-code-setting/procedure-code-setting.component';
import { PathologySettingComponent } from './pathology-setting/pathology-setting.component';
import { ImagingCenterSettingComponent } from './imaging-center-setting/imaging-center-setting.component';
import { ModalitySettingComponent } from './modality-setting/modality-setting.component';
import { PrioritySettingComponent } from './priority-setting/priority-setting.component';
import { ReportTemplateSettingComponent } from './report-template-setting/report-template-setting.component';
import { ReasonForExamComponent } from './reason-for-exam/reason-for-exam.component';
import { RoomSettingComponent } from './room-setting/room-setting.component';
import { BillingCodesComponent } from './billing-codes/billing-codes.component';
import { StaffContractComponent } from './staff-contract/staff-contract.component';
import { EditStaffContractComponent } from './staff-contract/edit-staff-contract/edit-staff-contract.component';
import { ViewersComponent } from './viewers/viewers.component';
import { PrintingModelsComponent } from './printing-models/printing-models.component';
import { SmsTemplatesComponent } from './sms-templates/sms-templates.component';
import { DefaultValuesComponent } from './default-values/default-values.component';
import { FormsSetupComponent } from './forms-setup/forms-setup.component';
import { PeersSettingComponent } from './peers-setting/peers-setting.component';
import { LabelSettingComponent } from './label-setting/label-setting.component';
import { FormsBuilderComponent } from './forms-builder/forms-builder.component';
import { UnavailabilityComponent } from './unavailability/unavailability.component';

const routes: Routes = [
	{
		path: '',
		component: SettingComponent,
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'schedule-setting' },
			{ path: 'profile-setting', component: ProfileSettingComponent },
			{ path: 'viewers', component: ViewersComponent },
			{ path: 'forms-setup', component: FormsSetupComponent },
			{ path: 'staff-contract', component: StaffContractComponent },
			{
				path: 'edit-staff-contract',
				component: EditStaffContractComponent,
			},
			{ path: 'aet-setting', component: AetSettingComponent },
			{ path: 'users-setting', component: UsersSettingComponent },
			{
				path: 'procedure-code-setting',
				component: ProcedureCodeSettingComponent,
			},
			{ path: 'billing-code-setting', component: BillingCodesComponent },
			{ path: 'pathology-setting', component: PathologySettingComponent },
			{
				path: 'imaging-center-setting',
				component: ImagingCenterSettingComponent,
			},
			{ path: 'modality-setting', component: ModalitySettingComponent },
			{ path: 'priority-setting', component: PrioritySettingComponent },
			{
				path: 'report-template-setting',
				component: ReportTemplateSettingComponent,
			},
			{ path: 'schedule-setting', component: GeneralSettingComponent },
			{ path: 'reason-for-exam', component: ReasonForExamComponent },
			{ path: 'rooms', component: RoomSettingComponent },
			{ path: 'default-values', component: DefaultValuesComponent },
			{ path: 'labels', component: LabelSettingComponent },
			{ path: 'unavailabilities', component: UnavailabilityComponent },
			{ path: 'printing-models', component: PrintingModelsComponent },
			{ path: 'sms-templates', component: SmsTemplatesComponent },
			{ path: 'peers-setting', component: PeersSettingComponent },
			{ path: 'forms-builder', component: FormsBuilderComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SettingRoutingModule {}
