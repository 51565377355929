import { Component } from '@angular/core';
import { SettingService } from '../setting.service';
import { ViewerDTO } from '../../model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { assign } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmComponent } from '../../shared';

@Component({
	selector: 'ft-viewers',
	templateUrl: './viewers.component.html',
	styleUrls: ['./viewers.component.scss'],
})
export class ViewersComponent {
	viewers: ViewerDTO[];
	viewerForm: FormGroup;

	constructor(
		private service: SettingService,
		private fb: FormBuilder,
		private dialog: MatDialog
	) {
		this.viewerForm = this.fb.group(
			assign(new ViewerDTO(), { name: ['', Validators.required] })
		);
		this.getViewers();
	}

	private getViewers(): void {
		this.service.getViewers().subscribe(data => (this.viewers = data));
	}

	saveViewer(value: ViewerDTO) {
		this.service.createViewer(value).subscribe(res => {
			if (res) this.getViewers();
		});
	}

	deleteViewer(viewer: ViewerDTO) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok)
					this.service.deleteViewer(viewer).subscribe(value => {
						if (value) this.getViewers();
					});
			});
	}

	addViewer() {
		this.viewers.push(new ViewerDTO());
	}

	selectViewer(viewer: ViewerDTO = new ViewerDTO()) {
		this.viewerForm.patchValue(viewer);
	}
}
