import { Observable, of as observableOf } from 'rxjs';

import { debounceTime } from 'rxjs/operators';
import { Component, inject, OnInit } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { deleteItemFromArray, SharedService } from '../../../shared';

import { SettingService } from '../../setting.service';
import { assign, find } from 'lodash';
import { StaffContractDTO, UserAccountDTO } from '../../../model';
import { BoxEditComponent } from '../../box-edit/box-edit.component';

function passwordMatcher(c: AbstractControl) {
	return c.get('password').value === c.get('confirmPassword').value
		? null
		: { nomatch: true };
}

export function required(min: number, max: number): any {
	return Validators.compose([
		Validators.required,
		Validators.minLength(min),
		Validators.maxLength(max),
	]);
}

@Component({
	selector: 'ft-user-edit',
	templateUrl: './user-edit.component.html',
	styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
	selectedUser: any;
	form: FormGroup;
	functions: any[];
	profiles: any[];

	exists = observableOf(false);
	color: string = '#E5E5E5';
	contracts: Observable<StaffContractDTO[]>;
	boxes = [];

	private sharedService = inject(SharedService);
	private settingService = inject(SettingService);
	private dialog = inject(MatDialog);
	private fb = inject(FormBuilder);
	private dialogRef = inject(MatDialogRef<UserEditComponent>);

	onChangeUserName() {
		this.form
			.get('username')
			.valueChanges.pipe(debounceTime(200))
			.subscribe(value => {
				if (value && !this.selectedUser.id)
					this.exists = this.settingService.isUserExists(value);
			});
	}

	onSave(data: UserAccountDTO) {
		data.color = this.color;

		this.settingService
			.saveUser(data)
			.subscribe(res => this.dialogRef.close(res));
	}

	getFunctions() {
		this.sharedService
			.getFunctions()
			.subscribe(data => (this.functions = data));
	}

	getStaffContracts() {
		this.contracts = this.sharedService.getAllStaffContracts();
	}

	getProfiles() {
		this.sharedService
			.getShortProfiles()
			.subscribe(data => (this.profiles = data));
	}

	ngOnInit() {
		this.createForm();
		this.onChangeUserName();

		this.getFunctions();
		this.getStaffContracts();
		this.getProfiles();
		this.getBoxes();

		this.selectedUser.password = '';
		this.form.patchValue(this.selectedUser);

		this.color = this.selectedUser.color;
	}

	private createForm() {
		this.form = this.fb.group(
			assign(new UserAccountDTO(), {
				username: ['', required(3, 12)],
				password: ['', required(3, 64)],
				confirmPassword: ['', required(3, 64)],
				firstName: ['', required(3, 64)],
				lastName: ['', required(3, 64)],
				functionId: [null, Validators.required],
				profileId: [null, Validators.required],
			}),
			{ validator: passwordMatcher }
		);
	}

	private getBoxes() {
		this.sharedService.getBoxes().subscribe(value => {
			this.boxes = value;
		});
	}

	createBox() {
		this.dialog
			.open(BoxEditComponent)
			.afterClosed()
			.subscribe(res => this.updateBox(res));
	}

	editBox(box: any) {
		this.dialog
			.open(BoxEditComponent, { data: box })
			.afterClosed()
			.subscribe(res => {
				if (res) deleteItemFromArray(this.boxes, box);
				this.updateBox(res);
			});
	}

	updateBox(box: any) {
		if (box && box.id !== 0) {
			this.boxes.push(box);
			this.form.get('boxId').patchValue(box?.id);
		}
	}

	deleteBox(box: any) {
		box.deleted = true;
		this.sharedService.saveBox(box).subscribe(res => {
			if (res) {
				deleteItemFromArray(this.boxes, res);
				this.form.get('boxId').patchValue(null);
			}
		});
	}
}
