<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
	<mat-icon fontIcon="mdi-credit-card" fontSet="mdi"></mat-icon>
	<h3>{{ 'BILLING_CODES' | translate }}</h3>

	<span class="fx-grow-1"></span>

	<button
		[matTooltip]="'ADD_NEW' | translate"
		mat-button
		(click)="editBillingCode({})">
		<mat-icon
			class="add-icon-gradient"
			fontIcon="mdi-plus"
			fontSet="mdi"></mat-icon>
		{{ 'ADD_NEW' | translate }}
	</button>
</mat-toolbar>

<div
	class="fx-search-area fx-layout-row-nowrap fx-gap-4 fx-padding-8 fx-content-start fx-items-center">
	<mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
		<mat-icon
			matIconPrefix
			fontIcon="mdi-magnify"
			fontSet="mdi"
			tabindex="-1"></mat-icon>
		<input matInput #filter [placeholder]="'SEARCH' | translate" />
	</mat-form-field>

	@if (isLoadingResults || isRateLimitReached) {
		<mat-spinner
			mode="indeterminate"
			[strokeWidth]="2"
			[diameter]="20"></mat-spinner>
	}
</div>

<div class="relative fx-layout-column-nowrap ft-setting-card-width">
	<mat-table
		[dataSource]="dataSource"
		class="fx-grow-1 fx-overflow-auto"
		style="height: calc(100vh - 208px) !important; padding: 0 16px"
		[trackBy]="trackById"
		matSort
		matSortActive="code"
		matSortDirection="asc"
		matSortDisableClear>
		<!--Table-->
		@for (col of displayedColumns; track col) {
			<ng-container
				cdkColumnDef="{{ col.label }}"
				[class.hidden]="col.hidden">
				<mat-header-cell
					*cdkHeaderCellDef
					mat-sort-header="{{ col.value }}"
					[disabled]="!col.sortable">
					{{ col.header | translate }}</mat-header-cell
				>

				<ng-container>
					<mat-cell *cdkCellDef="let row">
						{{ row[col.label] }}</mat-cell
					>
				</ng-container>
			</ng-container>
		}

		<!--actions-->
		<ng-container cdkColumnDef="action">
			<mat-header-cell *cdkHeaderCellDef></mat-header-cell>
			<mat-cell *cdkCellDef="let row">
				<button
					mat-icon-button
					(click)="$event.stopPropagation(); editBillingCode(row)">
					<mat-icon
						class="edit-icon-gradient"
						fontIcon="mdi-pencil"
						fontSet="mdi"></mat-icon>
				</button>
				<button
					mat-icon-button
					(click)="$event.stopPropagation(); deleteBillingCode(row)">
					<mat-icon
						class="delete-icon-gradient"
						fontIcon="mdi-delete"
						fontSet="mdi"></mat-icon>
				</button>
			</mat-cell>
		</ng-container>

		<!--header-->
		<mat-header-row *cdkHeaderRowDef="cols; sticky: true"></mat-header-row>
		<mat-row [@rowsAnimation]="" *cdkRowDef="let row; columns: cols">
		</mat-row>
	</mat-table>

	<mat-paginator
		class="fx-height-46"
		[length]="resultsLength"
		[pageIndex]="0"
		[pageSize]="25"
		[pageSizeOptions]="[5, 10, 25, 100]"
		[showFirstLastButtons]="true">
	</mat-paginator>
</div>
