<form style="padding: 24px" [formGroup]="form" (ngSubmit)="onSubmit(model)">
	<formly-form
		[model]="model"
		[fields]="fields"
		[options]="options"
		[form]="form"></formly-form>
	<button
		type="submit"
		mat-raised-button
		color="primary"
		[disabled]="!form.valid">
		Submit
	</button>
</form>
