<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
	<mat-icon fontIcon="mdi-incognito" fontSet="mdi"></mat-icon>
	<h3>{{ 'PROFILES' | translate }}</h3>

	<span class="fx-grow-1"></span>

	<button mat-button (click)="editProfile({})">
		<mat-icon
			class="add-icon-gradient"
			fontIcon="mdi-plus"
			fontSet="mdi"></mat-icon>
		{{ 'ADD_NEW' | translate }}
	</button>
</mat-toolbar>

<div class="relative fx-layout-column-nowrap ft-setting-card-width">
	<mat-table
		[dataSource]="dataSource"
		class="fx-grow-1 fx-overflow-auto"
		style="height: calc(100vh - 148px) !important; padding: 0 16px"
		matSort>
		<!--Table-->
		@for (col of displayedColumns; track col) {
			<ng-container
				cdkColumnDef="{{ col.label }}"
				[class.hidden]="col.hidden">
				<mat-header-cell *cdkHeaderCellDef mat-sort-header>
					{{ col.header | translate }}
					@if (col.unit !== 'date' && col.unit !== 'color') {
						<span>{{ col.unit }}</span>
					}
				</mat-header-cell>

				@if (col.unit === 'date') {
					<mat-cell *cdkCellDef="let row">
						{{ row[col.label] | date: 'dd/MM/yyyy' }}</mat-cell
					>
				}
				@if (col.unit !== 'date') {
					<mat-cell *cdkCellDef="let row">
						{{ row[col.label] }}</mat-cell
					>
				}
			</ng-container>
		}

		<!--actions-->
		<ng-container cdkColumnDef="action">
			<mat-header-cell *cdkHeaderCellDef></mat-header-cell>
			<mat-cell *cdkCellDef="let row" style="text-align: right">
				<button
					mat-icon-button
					(click)="$event.stopPropagation(); editProfile(row)">
					<mat-icon
						class="edit-icon-gradient"
						fontIcon="mdi-pencil"
						fontSet="mdi"></mat-icon>
				</button>
				<button
					mat-icon-button
					(click)="$event.stopPropagation(); deleteProfile(row)">
					<mat-icon
						class="delete-icon-gradient"
						fontIcon="mdi-delete"
						fontSet="mdi"></mat-icon>
				</button>
			</mat-cell>
		</ng-container>

		<!--header-->
		<mat-header-row *cdkHeaderRowDef="cols; sticky: true"></mat-header-row>
		<mat-row *cdkRowDef="let row; columns: cols"> </mat-row>
	</mat-table>

	<mat-paginator
		class="fx-height-46"
		[length]="dataSource.filteredData.length"
		[pageIndex]="0"
		[pageSize]="10"
		[pageSizeOptions]="[5, 10, 25, 100]"
		[showFirstLastButtons]="true">
	</mat-paginator>
</div>
