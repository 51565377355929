import {
	BehaviorSubject,
	fromEvent as observableFromEvent,
	merge,
	of as observableOf,
} from 'rxjs';

import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { SettingService } from '../setting.service';
import { DeleteConfirmComponent, SharedService } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BCODE_HEADER_COLS, BCODE_TABLE_CONF } from './table-conf';
import { rowsAnimation } from '../../animations';
import { BillingCodeDTO } from '../../model';
import { BillingCodeEditComponent } from './billing-code-edit/billing-code-edit.component';

@Component({
	selector: 'ft-billing-codes',
	templateUrl: './billing-codes.component.html',
	styleUrls: ['./billing-codes.component.scss'],
	animations: [rowsAnimation],
})
export class BillingCodesComponent implements AfterViewInit {
	cols: any[];
	displayedColumns = [];

	dataSource = new MatTableDataSource<BillingCodeDTO>();
	@ViewChild('filter', { static: true }) filter: ElementRef;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	filterChange = new BehaviorSubject('');
	trackById = (_: number, item: any): string => item.id;

	constructor(
		private service: SettingService,
		private sharedService: SharedService,
		public dialog: MatDialog
	) {
		this.displayedColumns = BCODE_TABLE_CONF;
		this.cols = BCODE_HEADER_COLS;
	}

	editBillingCode(code: BillingCodeDTO) {
		this.dialog
			.open(BillingCodeEditComponent, { data: code, disableClose: true })
			.afterClosed()
			.subscribe(res => {
				if (res) this.filterChange.next('');
			});
	}

	deleteBillingCode(code: BillingCodeDTO) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok) {
					this.service
						.deleteBillingCode(code.id)
						.subscribe(res => this.filterChange.next(''));
				}
			});
	}

	ngAfterViewInit() {
		observableFromEvent(this.filter.nativeElement, 'keyup')
			.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(() => {
				if (!this.dataSource) return;
				this.paginator.pageIndex = 0;
				this.filterChange.next(this.filter.nativeElement.value);
			});

		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		const observedFilters = [
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.filterChange.asObservable(),
		];

		merge(...observedFilters)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					return this.sharedService.getPaginatedBillingCodes(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						this.filterChange.getValue()
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];

					return data['content'] as BillingCodeDTO[];
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}
}
