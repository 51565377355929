import { Component } from '@angular/core';
import { FormSetupService } from '@ft/form';

@Component({
	selector: 'ft-forms-setup',
	templateUrl: './forms-setup.component.html',
	styleUrls: ['./forms-setup.component.scss'],
})
export class FormsSetupComponent {
	public dataHasLoaded: boolean;

	constructor(public setupService: FormSetupService) {
		setupService
			.loadFormsTemplates()
			.subscribe(_ => (this.dataHasLoaded = true));
	}
}
