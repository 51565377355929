<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
	<mat-icon fontIcon="mdi-file-question" fontSet="mdi"></mat-icon>
	<h3>{{ 'REASONSFOREXAM' | translate }}</h3>
</mat-toolbar>

<div
	class="relative fx-layout-column-nowrap fx-content-center fx-items-center ft-setting-card-width"
	style="padding-top: 24px">
	<mat-accordion>
		@for (reasonForExam of reasonForExams; track reasonForExam) {
			<mat-expansion-panel (opened)="selectReasonForExam(reasonForExam)">
				<mat-expansion-panel-header>
					<mat-panel-title [style.max-width.px]="100">
						<mat-chip-listbox>
							<mat-chip-option
								selectable="false"
								[style.background-color]="reasonForExam.color"
								>{{ reasonForExam.value }}</mat-chip-option
							>
						</mat-chip-listbox>
					</mat-panel-title>
					<mat-panel-description>
						{{ reasonForExam.description }}
					</mat-panel-description>
				</mat-expansion-panel-header>

				<div
					class="fx-layout-column-nowrap"
					[formGroup]="reasonForExamForm">
					<mat-form-field class="fx-fill-width">
						<mat-label>{{ 'CODE' | translate }}</mat-label>
						<input
							matInput
							placeholder="{{ 'CODE' | translate }}"
							formControlName="value"
							[maxlength]="4" />
					</mat-form-field>

					<mat-form-field class="fx-fill-width">
						<mat-label>{{
							'DAYS_BEFORE_RESULT' | translate
						}}</mat-label>
						<input
							matInput
							type="number"
							placeholder="{{ 'DAYS_BEFORE_RESULT' | translate }}"
							formControlName="daysBeforeExamResult"
							[min]="0" />
					</mat-form-field>

					<mat-form-field class="fx-fill-width">
						<mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
						<textarea
							matInput
							placeholder="{{ 'DESCRIPTION' | translate }}"
							formControlName="description"></textarea>
					</mat-form-field>

					<mat-radio-group
						class="fx-layout-row-nowrap fx-content-start"
						formControlName="reportOption">
						@for (
							reportOption of ['WITHOUT', 'WITH'];
							track reportOption
						) {
							<mat-radio-button [value]="reportOption">
								{{ reportOption | translate }}
							</mat-radio-button>
						}
					</mat-radio-group>

					<div class="ft-color">
						<div>{{ 'COLOR' | translate }}:</div>
						<div
							class="color-area"
							[(colorPicker)]="currentReasonForExamColor"
							[style.background]="
								currentReasonForExamColor
							"></div>
						<span class="fx-grow-1"></span>

						<button
							mat-button
							color="warn"
							(click)="deleteReasonForExam(reasonForExam)">
							{{ 'DELETE' | translate }}
						</button>
						<button
							mat-raised-button
							color="primary"
							[disabled]="reasonForExamForm.invalid"
							(click)="saveRFE(reasonForExamForm.value)">
							{{ 'SAVE' | translate }}
						</button>
					</div>
				</div>
			</mat-expansion-panel>
		}
	</mat-accordion>

	<button
		mat-fab
		[matTooltip]="'ADD_NEW' | translate"
		(click)="addReasonForExam()"
		style="position: fixed; bottom: 46px; right: 56px">
		<mat-icon
			class="add-icon-gradient"
			fontIcon="mdi-plus"
			fontSet="mdi"></mat-icon>
	</button>
</div>
