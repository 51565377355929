import { Component } from '@angular/core';
import { SettingService } from '../setting.service';
import { PrintingTemplateModel } from '../../model';

@Component({
	selector: 'ft-printing-models',
	templateUrl: './printing-models.component.html',
	styleUrls: ['./printing-models.component.scss'],
})
export class PrintingModelsComponent {
	printingFiles: PrintingTemplateModel[] = [];
	selectedFile: any;

	constructor(private setting: SettingService) {
		this.setting.getPrintingModels().subscribe(data => {
			this.printingFiles = data;
			this.selectedFile = data.find(it => it.name === 'welcome');
		});
	}

	openFile(file: any) {
		this.selectedFile = file;
	}

	editorReady($event: string) {}
}
