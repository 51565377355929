import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeleteConfirmComponent, SharedService } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import { SmsTemplateDTO } from '../../model';
import { assign } from 'lodash';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
	selector: 'ft-sms-templates',
	templateUrl: './sms-templates.component.html',
	styleUrls: ['./sms-templates.component.scss'],
})
export class SmsTemplatesComponent implements OnInit {
	smsTemplates: SmsTemplateDTO[] = [];
	smsTemplateForm!: FormGroup;

	@ViewChild('autosize') autosize: CdkTextareaAutosize;

	private service = inject(SharedService);
	private dialog = inject(MatDialog);
	private fb = inject(FormBuilder);

	private getSmsTemplates(): void {
		this.service
			.getSmsTemplates()
			.subscribe(data => (this.smsTemplates = data));
	}

	saveSmsTemplate(smsTemplate: SmsTemplateDTO) {
		this.service.saveSmsTemplate(smsTemplate).subscribe(res => {
			if (res) this.getSmsTemplates();
		});
	}

	deleteSmsTemplate(smsTemplate: SmsTemplateDTO) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok)
					this.service
						.deleteSmsTemplate(smsTemplate.id)
						.subscribe(value => {
							if (value) this.getSmsTemplates();
						});
			});
	}

	addSmsTemplate() {
		this.smsTemplates.push(new SmsTemplateDTO());
	}

	selectSmsTemplate(smsTemplate: SmsTemplateDTO = new SmsTemplateDTO()) {
		this.smsTemplateForm.patchValue(smsTemplate);
	}

	copyVariable(ev: any) {
		const variable = ev.target['outerText'];

		const el = document.getElementById('sms-body') as HTMLTextAreaElement;
		el.focus({ preventScroll: false });
		el.setRangeText(variable, el.selectionStart, el.selectionEnd, 'end');
	}

	ngOnInit(): void {
		this.smsTemplateForm = this.fb.group(
			assign(new SmsTemplateDTO(), {
				id: null,
				title: [
					'',
					Validators.compose([
						Validators.required,
						Validators.minLength(2),
					]),
				],
				body: [
					'',
					Validators.compose([
						Validators.required,
						Validators.minLength(2),
					]),
				],
				is_default: false,
			})
		);

		this.getSmsTemplates();
	}
}
