import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
	buildClosingDays,
	getCalendarViews,
	getDays,
	getMomentTime,
	getTimeSlots,
	patchClosingDays,
	SharedService,
} from '../../shared';
import { filter as _filter, find as _find, assign } from 'lodash';
import { SettingService } from '../setting.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GeneralSettingDTO, CalendarSettingDTO } from '../../model';
import { Observable, Subscription } from 'rxjs';
import { AppConfigService } from '../../app-config.service';
import { STATISTIC_VIEWS } from '../../utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';

const UI_LANGUAGES = [
	{ key: 'en', value: 'English' },
	{ key: 'fr', value: 'Français' },
	{ key: 'nl', value: 'Dutch' },
];

@Component({
	selector: 'ft-general-setting',
	templateUrl: './general-setting.component.html',
	styleUrls: ['./general-setting.component.scss'],
})
export class GeneralSettingComponent implements OnInit, OnDestroy {
	calendarSetting: CalendarSettingDTO = new CalendarSettingDTO();
	generalSetting: GeneralSettingDTO;

	days: any[];
	slots = [];
	views: any[];
	exams: Observable<any[]>;

	idGenerationModes = [
		{ name: 'MANUAL', checked: true },
		{ name: 'AUTOMATIC', checked: false },
	];
	printingModes = [
		{ name: 'CHROME', checked: true },
		{ name: 'CUPS', checked: false },
	];
	calFormGroup!: FormGroup;
	statisticViews = STATISTIC_VIEWS;
	languages = UI_LANGUAGES;
	private _subs: Subscription[] = [];

	private _shared = inject(SharedService);
	private _setting = inject(SettingService);
	private _translate = inject(TranslateService);
	private _snack = inject(MatSnackBar);
	private _appConfig = inject(AppConfigService);
	private _fb = inject(FormBuilder);

	onSelectView(_: any) {
		const dv = this.calFormGroup.controls['defaultView'].get('id');
		this.calendarSetting.defaultView = this.views.find(
			v => v.id === dv.value
		).key;
		this.updateTimes();
		this.saveCalendarSetting(this.calendarSetting);
	}

	onSelectSchedulerView(e: any) {
		this.generalSetting.schedulerDefaultView = e.value;
		this.updateTimes();
		this.saveGeneralSetting(this.generalSetting);
	}

	onSelectTime(_: any) {
		const slot = this.calFormGroup.value.minTimeSlot;

		this.calendarSetting.minTimeSlot = _find(this.slots, slot).value;

		this.updateTimes();

		this.saveCalendarSetting(this.calendarSetting);
	}

	private updateTimes(): void {
		this.calendarSetting.openingTime = getMomentTime(
			this.calFormGroup.value.openingTime,
			'YYYY-MM-DDTHH:mm:ss'
		);
		this.calendarSetting.closingTime = getMomentTime(
			this.calFormGroup.value.closingTime,
			'YYYY-MM-DDTHH:mm:ss'
		);
	}

	onSelectDay(ev: any) {
		this.calendarSetting.closingDays = _filter(
			buildClosingDays(this.days, ev),
			d => d.checked
		)
			.map(d => d.key)
			.join(';');
		this.updateTimes();
		this.saveCalendarSetting(this.calendarSetting);
	}

	saveCalendarSetting(calSetting: CalendarSettingDTO): void {
		this._setting.saveCalendarSetting(calSetting).subscribe();
		localStorage.setItem('calSetting', JSON.stringify(calSetting));
	}

	ngOnInit() {
		this.calFormGroup = this._fb.group(
			assign(new CalendarSettingDTO(), {
				defaultView: this._fb.group({ id: 2 }),
				minTimeSlot: this._fb.group({ id: 3 }),
			})
		);

		this.slots = getTimeSlots();
		this.days = getDays(this._appConfig.appLang);
		this.views = getCalendarViews(this._appConfig.appLang);

		this.exams = this._shared.getReasonForExams();
		this.generalSetting = this._appConfig.generalSetting;

		this.getCalendarSetting(this.generalSetting.imagingCenterId);
		this.getGeneralSetting(this.generalSetting.imagingCenterId);
	}

	getCalendarSetting(centerId: number) {
		this._subs.push(
			this._setting.getCalendarSetting(centerId).subscribe(calSetting => {
				this.calendarSetting = calSetting;
				this.calendarSetting.openingTime = moment(
					calSetting.openingTime,
					'YYYY-MM-DDTHH:mm:ss'
				).format('HH:mm');
				this.calendarSetting.closingTime = moment(
					calSetting.closingTime,
					'YYYY-MM-DDTHH:mm:ss'
				).format('HH:mm');

				this.calFormGroup.patchValue(this.calendarSetting);

				patchClosingDays(this.days, calSetting.closingDays);

				const view = this.views.find(
					v => v.key === calSetting.defaultView
				);
				const slot = this.slots.find(
					val => Number(val.value) === calSetting.minTimeSlot
				);

				this.calFormGroup.controls['defaultView'].patchValue(view);
				this.calFormGroup.controls['minTimeSlot'].patchValue(
					slot ? slot : 20
				);
			})
		);
	}

	getGeneralSetting(centerId: number) {
		this._subs.push(
			this._setting.getGeneralSetting(centerId).subscribe(settings => {
				if (settings) this.generalSetting = settings;
			})
		);
	}

	saveGeneralSetting(generalSetting: GeneralSettingDTO): void {
		generalSetting.logoutTimeout = Math.abs(generalSetting.logoutTimeout);
		this._subs.push(
			this._setting.saveGeneralSetting(generalSetting).subscribe(ok => {
				if (ok)
					this._snack.open(
						this._translate.instant('changes_saved'),
						'',
						{ duration: 2000 }
					);
			})
		);
	}

	ngOnDestroy = (): void => this._subs.forEach(sub => sub.unsubscribe());
	reload = () => location.reload();
}
